import React from 'react';
import { PropsWithChildren } from 'react';
import Base from './base/Base';

export type FullPageProps<T = {}> = PropsWithChildren<T>;

export const FullPage: React.FunctionComponent<FullPageProps> = ({ children }: FullPageProps) => (
  <Base>
    <div style={{ display: 'contents' }}>
      <div style={{ width: '100%', maxWidth: 1320, minHeight: '40vh', display: 'grid' }}>{children}</div>
    </div>
  </Base>
);
